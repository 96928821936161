import React from "react";
import { Link } from "gatsby";
import { FiExternalLink } from 'react-icons/fi';

import Image from "../components/image";
import Hilarousel from '../components/Hilarousel';
import SEO from "../components/seo";

const IndexPage = () => (
  <>
    <SEO title="Home" />

    <section id="hero" className="hero">
      <div className="hero__container">
        <Hilarousel />
        <h1>Software Development <br/> & Experience Design.</h1>
      </div>
    </section>

    {
      // <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      //   <Image />
      // </div>
    }

    <section id="work" className="container">
      <div className="homework">
        <img src="/stacks.png" />
        <a href="https://bitstacks.io/" target="_blank" rel="noreferrer">
          <h2>Bitstacks <FiExternalLink /></h2>
        </a>
        <p>Founder, Product Design & Development</p>
        <p>
          Portfolio management for crypto-assets.
          Uses a suite of technical indicators and proprietary algorithms to publish real time-trade signals.
          Features an intuitive dashboard to monitor multiple assets.
        </p>

        <p style={{ margin: '0px 0px .3rem' }}>
          Tech Stack
        </p>
        <ul className="tech-stack">
          <li>React</li>
          <li>Redux</li>
          <li>TypeScript</li>
          <li>Node</li>
          <li>Express</li>
          <li>Postgres</li>
          <li>TypeORM</li>
          <li>Redis</li>
          <li>Recharts</li>
          <li>Gatsby</li>
          <li>Netlify</li>
        </ul>
      </div>

      <div className="homework">
        <img src="/popchest.svg" />
        <h2>Popchest <span>Decentralized Video Network</span></h2>
        <p>Lead Front-End Engineer</p>
        <p>
          Ad, censorship, middleman-free video platform for the people.
          Enables content creators to accept payments directly from fans via Ethereum micropayments.
          Utilizes Webtorrent technology to create a peer-to-peer video distribution network.
        </p>

        <p style={{ margin: '0px 0px .3rem' }}>
          Tech Stack
        </p>
        <ul className="tech-stack">
          <li>React</li>
          <li>Elm</li>
          <li>Node</li>
          <li>Express</li>
          <li>Postgres</li>
          <li>Solidity</li>
          <li>Web3</li>
          <li>Storybook</li>
          <li>Sketch</li>
          <li>Zeplin</li>
        </ul>
      </div>

      <div className="homework">
        <img src="/wevr.png" />
        <h2>Wevr <span>VR Studios</span></h2>
        <p>Software Engineer, UX/UI Designer</p>
        <p>
          Designed and engineered a suite of applications for a very cool VR studio in Venice, CA.
        </p>
        <p>
          Lead product design and development of a page builder app, tailor made for in-house creatives to publish dynamic web pages for featured titles.
        </p>
        <p>
          Developed a web interface for VR producers allowing them to upload projects and share directly to remote headsets via the Transport platform.
        </p>
        <p>
          Created a venue management and event booking application for out-of-home VR experiences. Event staff could publish event listings, add featured titles, and manage guest checkins via admin interface.
        </p>

        <p style={{ margin: '0px 0px .3rem' }}>
          Tech Stack
        </p>
        <ul className="tech-stack">
          <li>React</li>
          <li>Redux</li>
          <li>GraphQl</li>
          <li>Node</li>
          <li>Express</li>
          <li>Postgres</li>
          <li>Photoshop</li>
          <li>Adobe XD</li>
        </ul>
      </div>

      <div className="homework">
        <img src="/xaxis.png" />
        <h2>Xaxis <span>AdLabs</span></h2>
        <p>Interactive Developer</p>
        <p>
          Engineered rich-media interactive ad campaigns for brands including Nordstrom, Nature Made, John Deere, Flor and Jaguar.
        </p>
        <p>
          Designed a dynamic ad framework enabling account managers to deploy hundreds of content variations without updating code.
        </p>
        <p>
          Created a responsive template which programmatically generated a full suite of ad units from a single base layout.
        </p>

        <p style={{ margin: '0px 0px .3rem' }}>
          Tech Stack
        </p>
        <ul className="tech-stack">
          <li>Javascript</li>
          <li>DoubleClick Studio</li>
          <li>Hammer.js</li>
        </ul>
      </div>
    </section>

    {
      // <section className="container">
      //   <div className="homework">
      //     <h2>DueBetter</h2>
      //     <p>
      //       Personal development tool mixed with crypto bets for extra motivation.
      //       Utilizes Ethereum state channels to allow users to stake wagers on tasks and goals without prohibitive gas fees and confirmation times.
      //       User authentication and funds are secured through an integrated Ethereum wallet.
      //     </p>
      //   </div>
      // </section>
    }
  </>
)

export default IndexPage
