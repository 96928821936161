import React, { useEffect, useState } from 'react';
import './hilarousel.scss';

const srcs = [
  'https://media.giphy.com/media/uX5BYSQALx12o/giphy.gif',
  'https://media.giphy.com/media/l4Ki2obCyAQS5WhFe/giphy.gif',
  'https://media.giphy.com/media/LmNwrBhejkK9EFP504/giphy.gif',
  'https://media.giphy.com/media/7LrE3VMOuU6Dm/giphy.gif',
  'https://media.giphy.com/media/l49JMVDvP8D38LHwI/giphy.gif',
  'https://media.giphy.com/media/dvreHY4p06lzVSDrvj/giphy.gif',
  'https://media.giphy.com/media/EimNpKJpihLY4/giphy.gif',
  'https://media.giphy.com/media/13yQp5Xf7YsLcs/giphy.gif',
  'https://media.giphy.com/media/3oriO04qxVReM5rJEA/giphy.gif',
  'https://media.giphy.com/media/l44Qqz6gO6JiVV3pu/giphy.gif',
  'https://media.giphy.com/media/14cFqOvMjntZyE/giphy.gif',
  'https://media.giphy.com/media/l41lIvPtFdU3cLQjK/giphy.gif',
  'https://media.giphy.com/media/cRq8TJsIKmQRG/giphy.gif',
  'https://media.giphy.com/media/ORjDJ8ZRAEjPyPhUu2/giphy.gif',
  'https://media.giphy.com/media/l3q2zbskZp2j8wniE/giphy-downsized-large.gif',
  'https://media.giphy.com/media/xTiTnumdcQABBLgM92/giphy.gif',
  'https://media.giphy.com/media/nrR3UCKd3yRG0/giphy.gif',
  'https://media.giphy.com/media/l0Iyftjqqu1CDhDZS/giphy-downsized-large.gif',
  'https://media.giphy.com/media/4cUCFvwICarHq/giphy.gif',
  'https://media.giphy.com/media/LqDEIKfIm5DtvPXPrf/giphy.gif',
  'https://media.giphy.com/media/NseJL9Zj9kttS/giphy.gif',
  'https://media.giphy.com/media/WS3i2y88foYpE584rI/giphy.gif',
  'https://media.giphy.com/media/eIfYQTaK3148kmMCxT/giphy.gif',
  'https://media.giphy.com/media/rdJCzxBWIvqGA/giphy.gif',
  'https://media.giphy.com/media/SFTKk5eoEJQl2/giphy.gif',
  'https://media.giphy.com/media/J93sVmfYBtsRi/giphy.gif',
  'https://media.giphy.com/media/141xGebUNJWiGI/giphy.gif',
  'https://media.giphy.com/media/gHhJH422vjB6GoJPTH/giphy.gif',
  'https://media.giphy.com/media/xUA7bijsxNm6iMh39e/giphy.gif',
  'https://media.giphy.com/media/uYuNfAcaAP06A/giphy.gif'
]

function Hilarousel() {
  const defaultSrc = 'https://media.giphy.com/media/3oriO04qxVReM5rJEA/giphy.gif';
  const [ selectedSrc, setSelectedSrc ] = useState(null);
  const [ shuffled, setShuffled ] = useState([]);

  useEffect(() => {
    shuffleGifs();
    preloadImages();
  }, []);

  useEffect(() => {
    if (shuffled.length > 0) {
      cycleSrc();
    } else {
      shuffleGifs();
    }
  }, [shuffled]);

  // function moveIt() {
  //   const hilar: any = document.querySelector('.hilarousel');
  //   const hero: any = document.querySelector('.hero');
  //   hero.addEventListener('mousemove', e => {
  //     console.log('moving', e.pageY)
  //     // hilar.style.display = 'none'; //e.pageY;
  //     hilar.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
  //   });
  // }

  function preload(url) {
    (new Image()).src = url;
  }

  function preloadImages() {
    srcs.forEach(img => preload(img));
  }

  function shuffleGifs() {
    const shuffled = shuffleArr(srcs);
    setShuffled(shuffled);
  }

  function cycleSrc() {
    setTimeout(() => {
      const newShuff = [ ...shuffled ];
      const selectedSrc = newShuff.pop();
      setShuffled(newShuff);
      setSelectedSrc(selectedSrc);
    }, 4000);
  }


  return (
    <div className="hilarousel">
      <img src={selectedSrc || defaultSrc} />
    </div>
  )
}

export default Hilarousel;

// helper
function shuffleArr(arr) {
  const array = [ ...arr ];
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}
